import { Link } from "gatsby"
import React from "react"
import ArbPdf from "../assets/arbRules.pdf"
import IconTwo from "../assets/images/icons/facebook.svg"
import IconThree from "../assets/images/icons/linkedin.svg"
import IconOne from "../assets/images/icons/mail.svg"
import IconFour from "../assets/images/icons/twitter.svg"
import IconFive from "../assets/images/icons/whatsapp.svg"
import Logo from "../assets/images/Logo_Colour.png"
import { demoForm } from "../constants"

const Terms = "https://app.webnyay.in/files/termsAndConditions.pdf"
const Policy = "https://app.webnyay.in/files/wenyayPrivacyPolicy.pdf"

const data = [
  { icon: IconOne, to: "mailto:admin@webnyay.in" },
  { icon: IconTwo, to: "https://www.facebook.com/Webnyay/" },
  { icon: IconThree, to: "https://www.linkedin.com/company/webnyay/" },
  { icon: IconFour, to: "https://twitter.com/webnyay" },
  { icon: IconFive, to: "https://wa.me/919958867718" },
]

export default function Footer() {
  return (
    <div id="footer" className="footer">
      <div className="footer__container ">
        <div className="footer__left">
          <Link
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="700"
            to="/"
          >
            <img className="footer__brand" src={Logo} alt="webnyay logo" />
          </Link>
          <div className="u-margin-top-small"></div>

          <p className="footer__query">
            Have questions, write to us at admin@webnyay.in
          </p>
          <div className="u-margin-top-small"></div>

          <div className="footer__social-icons">
            {data.map((singleData, index) => {
              return (
                <div
                  key={index}
                  data-sal="slide-up"
                  data-sal-delay="200"
                  data-sal-duration="700"
                >
                  <a target="__blank" href={singleData.to}>
                    <img
                      className="footer__social-icon"
                      src={singleData.icon}
                      alt="social_icons"
                    />
                  </a>
                </div>
              )
            })}
          </div>
          <div className="u-margin-top-small"></div>

          <p className="footer__copyright-text hidden-mobile">
            &copy; 2020 Copyright. all rights reserved.
          </p>
        </div>

        <div className="footer__right">
          <div className="u-margin-top-small"></div>

          <a target="__blank" href={demoForm}>
            <p className="footer__demo">BOOK A demo with us</p>
          </a>
          <div className="u-margin-top-medium"></div>

          <div className="footer__child-container">
            <a  target="__blank" href='/partners'>
              <p className="footer__copyright-text">Our Partners</p>
            </a >
            <div className="u-margin-left-medium"></div>
            <a target="__blank" href={Terms}>
              <p className="footer__copyright-text">Terms of Use</p>
            </a>
            <div className="u-margin-left-medium"></div>
            <a href={Policy} target="__blank">
              <p className="footer__copyright-text">Privacy Policy</p>
            </a>
            <div className="u-margin-left-medium"></div>
            <a href={ArbPdf} target="__blank">
              <p className="footer__copyright-text">
                Webnyay Arbitration Rules
              </p>
            </a>
            <div className="u-margin-left-medium"></div>
            <a href="/contact" target="__blank">
              <p className="footer__copyright-text">Contact Us</p>
            </a>
          </div>
          <div className="u-margin-top-small"></div>
          <p className="footer__caption">
            Webnyay provides online dispute resolution services at your
            direction. We are not a law firm or a substitute for any advocate or
            law firm. Communications between you and Webnyay are protected by
            our Privacy Policy, but not by any attorney-client privilege or as
            work product. We cannot provide any kind of advice, explanation,
            opinion, or recommendation about your legal rights, remedies,
            defenses, options, selection of forms or strategies. Your access to
            our website is subject to our Terms and Conditions
          </p>
          <div className="u-margin-top-small"></div>

          <p className="footer__copyright-text-mobile">
            &copy; 2020 Copyright. all rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}
